import React from "react";
import { useContentManager } from "../../ContentManager";
import StrengthsToggle from "../StrengthsToggle";

function ViaTraits({ traits, startExpanded = false, trackingData }) {
  const { wrapContent } = useContentManager();

  return (
    <React.Fragment>
      {traits?.map((trait, index) => {
        const { name, secondaryNameKey } = trait;

        return (
          <StrengthsToggle
            key={name}
            name={name}
            secondaryName={secondaryNameKey}
            rank={index + 1}
            description={wrapContent(trait, {
              border: true,
            })}
            startExpanded={startExpanded}
            trackingData={trackingData}
          />
        );
      })}
    </React.Fragment>
  );
}

export default ViaTraits;
