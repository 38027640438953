import React from "react";
import { string, number } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { withTooltip } from "../hoc/withTooltip";
import { iconProp } from "../utils/propTypes";
import { WHITE, GREY } from "../utils/theme";
import { Flex } from "./FlexBox";

const IconBackground = styled(Flex)`
  background-color: ${props => props.color};
  border-radius: 50%;
  min-height: unset;
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
  padding: 0;
`;

function IconWithCircleBackground({
  icon,
  color = GREY,
  width = 25,
  height = 25,
  iconSize = "lg",
}) {
  return (
    <IconBackground justify="center" align="center" color={color} height={height} width={width}>
      <FontAwesomeIcon fixedWidth icon={icon} color={WHITE} size={iconSize} style={{ width: "100%" }} />
    </IconBackground>
  );
}

IconWithCircleBackground.propTypes = {
  icon: iconProp.isRequired,
  color: string,
  width: number,
  height: number,
  iconSize: string,
};

export default withTooltip(IconWithCircleBackground);
