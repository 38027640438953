import React from "react";
import PropTypes from "prop-types";
import { trackingDataProp } from "../../../lib/graphql/propTypes";
import { Box } from "../../ui";
import { toggleState } from "../../utils";
import { iconProp } from "../../utils/propTypes";
import ToggleButtonComponent from "./ToggleButtonComponent";
import ToggleButtonDetails from "./ToggleButtonDetails";

class ToggleButton extends React.PureComponent {
  state = { showDetails: this.props.startExpanded }

  static propTypes = {
    title: PropTypes.node.isRequired,
    subtitle: PropTypes.node,
    details: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.node.isRequired,
      PropTypes.shape({
        showAvatars: PropTypes.bool,
        tabs: PropTypes.node,
        users: PropTypes.array,
      }),
    ]),
    disabled: PropTypes.bool,
    unexpandedDetails: PropTypes.node,
    startExpanded: PropTypes.bool,
    color: PropTypes.string,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    children: PropTypes.func,
    asHTML: PropTypes.bool, // Are the details an html string?
    icon: iconProp,
    center: PropTypes.bool,
    trackingData: trackingDataProp,
    testId: PropTypes.string,
  }

  static defaultProps = {
    subtitle: undefined,
    details: undefined,
    disabled: false,
    unexpandedDetails: undefined,
    startExpanded: false,
    color: undefined,
    onClick: () => undefined,
    onClose: () => undefined,
    children: undefined,
    asHTML: false,
    icon: undefined,
    center: false,
    trackingData: undefined,
    testId: undefined,
  }

  handleClick = () => {
    const { onClick } = this.props;

    this.setState(toggleState("showDetails"));

    return onClick();
  };

  handleClose = () => {
    const { onClose } = this.props;
    this.setState({ showDetails: false });

    return onClose();
  }

  render() {
    const {
      title,
      subtitle,
      color,
      details,
      disabled,
      unexpandedDetails,
      children,
      asHTML,
      icon,
      center,
      trackingData,
      testId,
    } = this.props;

    const { showDetails } = this.state;
    const isToggled = showDetails;

    return (
      <React.Fragment key={title}>
        <Box w={1}>
          {
            children
              ? children({
                onClick: this.handleClick,
                title,
                subtitle,
                toggled: isToggled,
                color,
                disabled,
              })
              : (
                <ToggleButtonComponent
                  onClick={this.handleClick}
                  title={title}
                  subtitle={subtitle}
                  toggled={isToggled}
                  color={color}
                  disabled={disabled}
                  icon={icon}
                  center={center}
                  trackingData={trackingData}
                  testId={testId}
                />
              )
          }
        </Box>
        {isToggled && details
          ? (
            <ToggleButtonDetails
              color={color}
              details={details}
              onClick={this.handleClose}
              asHTML={asHTML}
              trackingData={{
                ...trackingData,
                element: title,
              }}
            />
          ) : unexpandedDetails}
      </React.Fragment>
    );
  }
}

export default ToggleButton;
