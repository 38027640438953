import React from "react";
import { bool } from "prop-types";
import { useRouter } from "next/router";
import { gql, useMutation } from "@apollo/client";
import styled from "styled-components";
import { track } from "../../../../lib/analytics";
import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  TextButton
} from "../../../ui";
import IconWithCircleBackground from "../../../ui/IconWithCircleBackground";
import { EVENT, INSTINCTIVE_DRIVES_INTERIM_REPORT_URL } from "../../../utils/constants";
import { SEARCHING_DETAILS } from "../../../utils/strings";
import {
  WHITE,
  BLACK,
  GREEN
} from "../../../utils/theme";
import { useLocale } from "@/lib/hooks";

const SearchingDetailsList = styled.ul`
  list-style: none;
  padding: 0;
  & li {
    display: flex;
    margin-bottom: 16px;
  }
`;

const retakeInstinctiveDrivesMutation = gql`
  mutation retakeInstinctiveDrives {
    retakeInstinctiveDrives {
      alreadyTaken
      user {
        id
        scores {
          instinctiveDrives {
            isSearching
          }
        }
      }
    }
  }
`;

function InstinctiveDrivesSearchingResults({ showContinue = false }) {
  const router = useRouter();
  const { t } = useLocale();
  const [retakeInstinctiveDrives] = useMutation(retakeInstinctiveDrivesMutation);

  const handleRetake = () => {
    track(EVENT.InstinctiveDrivesRetakeAssessmentClicked.name);

    return retakeInstinctiveDrives().then(() => {
      return router.push("/instinctive-drives");
    });
  };

  const handleViewInterimReportClick = () => {
    track(EVENT.InstinctiveDrivesInterimReportDownloaded.name);
    if (typeof window !== "undefined") {
      return window.open(INSTINCTIVE_DRIVES_INTERIM_REPORT_URL);
    }

    return undefined;
  };

  const handleTakeLater = () => router.push("/my-dashboard");

  return (
    <Flex w={1} px={1} justify="center" column>
      <Box
        p={1}
        background={BLACK}
        style={{ borderRadius: 5 }}
      >
        <Heading
          size={3}
          uppercase
          bold
          noMargin
          color={WHITE}
          center
        >{t("dash.id.searching-title")}
        </Heading>
      </Box>
      <SearchingDetailsList>
        {SEARCHING_DETAILS.map(({ title, details, icon }) => (
          <li key={title}>
            <Box mr={1}>
              <IconWithCircleBackground width={50} height={50} color={GREEN} icon={icon} iconSize="2x" />
            </Box>
            <Box>
              <Heading size={4} noMargin uppercase bold>{title}</Heading>
              <Text noMargin>{details}</Text>
            </Box>
          </li>
        ))}
      </SearchingDetailsList>
      <Flex px={4} column align="center" w={1}>
        <Button primary block onClick={handleRetake}>{t("dash.retake-assessment")}</Button>
        <Box w={1} my={1}>
          <Button outline block onClick={handleViewInterimReportClick}>{t("dash.view-interim-report")}</Button>
        </Box>
        {showContinue && (
          <TextButton
            block
            color={GREEN}
            condensed
            noMargin
            uppercase
            onClick={handleTakeLater}
          >{t("dash.retake-assessment-later")}
          </TextButton>
        )}
      </Flex>
    </Flex>
  );
}

InstinctiveDrivesSearchingResults.propTypes = {
  showContinue: bool,
};

export default InstinctiveDrivesSearchingResults;
