import React from "react";
import { Box } from "../../../ui";
import TextBlock from "../../../ui/styled/TextBlock";
import { useContentManager } from "../../ContentManager";
import ToggleButton from "../../ToggleButton";
import LabeledSpectrum from "../LabeledSpectrum";
import ThinSpectrum from "../ThinSpectrum";
import {
  culturePulseSpectrumLabels,
  culturePulseSpectrumScore,
  getSubtitle,
  orderByTraitId,
  getTraitMiddleValue,
  CULTURE_PULSE_MAX_SCORE,
  CULTURE_PULSE_MIN_SCORE
} from "../utils";

function CulturePulseTraits({ traits, startExpanded = false, trackingData }) {
  const { wrapContent } = useContentManager();

  const getTraitDetails = (trait, label) => (
    <React.Fragment>
      <LabeledSpectrum
        title={trait.name || label.title}
        labels={label.endLabels}
        score={culturePulseSpectrumScore(trait.value)}
      />
      <TextBlock>{wrapContent(trait)}</TextBlock>
    </React.Fragment>
  )

  const orderedTraits = orderByTraitId(traits);

  return (
    <React.Fragment>
      {orderedTraits.map((trait) => {
        const cultureLabel = culturePulseSpectrumLabels.find(label => label?.title?.toLowerCase() === trait?.nameKey?.toLowerCase());
        const traitMiddleScore = getTraitMiddleValue(CULTURE_PULSE_MAX_SCORE, CULTURE_PULSE_MIN_SCORE);
        const subtitle = getSubtitle(cultureLabel, trait.value, traitMiddleScore);

        const spectrumScore = culturePulseSpectrumScore(trait.value);

        return (
          <Box w={1} key={trait.name} mt={1}>
            <ToggleButton
              small
              title={trait.name || cultureLabel.title}
              subtitle={subtitle}
              details={getTraitDetails(trait, cultureLabel)}
              startExpanded={startExpanded}
              unexpandedDetails={<ThinSpectrum score={spectrumScore} />}
              trackingData={trackingData}
            />
          </Box>
        );
      })}
    </React.Fragment>
  );
}

export default CulturePulseTraits;
