import React from "react";
import {
  string,
  number,
  shape,
  arrayOf
} from "prop-types";
import { trackingDataProp, user as userProp } from "../../../../lib/graphql/propTypes";
import { getFirstId, getId } from "../../../utils";
import { GREEN } from "../../../utils/theme";
import AvatarShape from "../../Charts/AvatarShape";

class TeamSpectrumScatterShape extends React.PureComponent {
  static propTypes = {
    primaryUser: userProp,
    xAxis: shape({}).isRequired,
    cx: number.isRequired,
    cy: number,
    w: number,
    type: string,
    users: arrayOf(userProp),
    assessment: string.isRequired,
    trackingData: trackingDataProp,
  }

  static defaultProps = {
    primaryUser: undefined,
    cy: undefined,
    w: undefined,
    type: undefined,
    users: undefined,
    trackingData: undefined,
  }

  isPrimaryUser = () => {
    const { users, primaryUser } = this.props;
    const userId = getFirstId(users);

    return userId === getId(primaryUser);
  }

  getAvatarSize = () => {
    const { users } = this.props;

    if (users.length > 1) return 1;

    if (this.isPrimaryUser()) return 2;

    return 1;
  }

  render() {
    const {
      type, cx, cy, w, xAxis, users, trackingData,
    } = this.props;

    if (type === "range") {
      return (
        <rect x={cx} rx={3} ry={3} width={w * xAxis.width} height="100%" fill={GREEN} fillOpacity={0.2} />
      );
    }

    if (type === "offset") {
      return (
        <rect x={cx} y={cy} rx={3} ry={3} width={w * xAxis.width} height={2} fill={GREEN} />
      );
    }

    if (type === "average") {
      return (
        <rect x={cx} y={5} rx={3} ry={3} width={2} height="75%" fill={GREEN} />
      );
    }

    return users && (
      <AvatarShape
        cx={cx}
        cy={cy}
        users={users}
        avatarSize={this.getAvatarSize()}
        border={this.isPrimaryUser()}
        assessment={this.props.assessment}
        trackingData={trackingData}
      />
    );
  }
}

export default TeamSpectrumScatterShape;
