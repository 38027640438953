import React from "react";
import { number, arrayOf } from "prop-types";
import styled from "styled-components";
import { Box, Flex } from "../../ui";
import { BLACK, GREY } from "../../utils/theme";
import { spectrumMargin, spectrumBorderRadius } from "./utils";

const SpectrumContainer = styled(Flex)`
  position: relative;
`;

const SpectrumBackground = styled.div`
  background-color: ${GREY};
  height: 2px;
  width: 100%;
`;

const SpectrumValue = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: ${props => (spectrumMargin(props.score, props.range))};
  background-color: ${BLACK};
  border-radius: ${props => spectrumBorderRadius(props.score, props.range)};
  height: 4px;
`;

class ThinSpectrum extends React.PureComponent {
  static propTypes = {
    score: number,
    range: arrayOf(number),
  }

  static defaultProps = {
    score: undefined,
    range: undefined,
  }

  render() {
    const { score, range } = this.props;

    return (
      <Box w={1} mt={1}>
        <SpectrumContainer h="4px" align="center">
          <SpectrumBackground>
            <SpectrumValue score={score} range={range} />
          </SpectrumBackground>
        </SpectrumContainer>
      </Box>
    );
  }
}

export default ThinSpectrum;
