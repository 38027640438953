import React from "react";
import { Markdown } from "grommet";
import { Box } from "../../../ui";
import TextBlock from "../../../ui/styled/TextBlock";
import ToggleButton from "../../ToggleButton";
import LabeledSpectrum from "../LabeledSpectrum";
import ThinSpectrum from "../ThinSpectrum";
import {
  motivatingValuesSpectrumLabels,
  motivatingValuesSpectrumScore,
  getSubtitle,
  orderByTraitId,
  getTraitMiddleValue,
  MOTIVATING_VALUES_MAX_SCORE,
  MOTIVATING_VALUES_MIN_SCORE
} from "../utils";
import { useContentManager } from "@/components/composite/ContentManager";
import { useLocale } from "@/lib/hooks";

function MotivatingValuesTraits({
  traits,
  startExpanded = false,
  trackingData,
}) {
  const { t } = useLocale();
  const { wrapContent } = useContentManager();
  const orderedTraits = orderByTraitId(traits);

  const getTraitDetails = (trait, labels) => {
    let description = wrapContent(trait, {
      border: true,
    });
    if (typeof description === "string") {
      description = (
        <TextBlock>
          <Markdown
            components={{
              p: {
                props: {
                  size: "small",
                }
              },
            }}
          >
            {description}
          </Markdown>
        </TextBlock>
      );
    }

    return (
      <React.Fragment>
        {description}
        {(trait.value || trait.value === 0) && (
          <LabeledSpectrum
            title={labels.title}
            labels={labels.endLabels}
            score={motivatingValuesSpectrumScore(trait.value)}
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {orderedTraits.map((trait) => {
        const motivatingValueLabels = motivatingValuesSpectrumLabels(t).find(label => label?.dbName?.toLowerCase() === trait?.nameKey?.toLowerCase());
        const traitMiddleScore = getTraitMiddleValue(MOTIVATING_VALUES_MAX_SCORE, MOTIVATING_VALUES_MIN_SCORE);
        const subtitle = getSubtitle(motivatingValueLabels, trait.value, traitMiddleScore);

        const spectrumScore = motivatingValuesSpectrumScore(trait.value);

        return (
          <Box w={1} key={trait.name} mt={1}>
            <ToggleButton
              small
              title={trait.name}
              subtitle={subtitle}
              details={getTraitDetails(trait, motivatingValueLabels)}
              startExpanded={startExpanded}
              unexpandedDetails={<ThinSpectrum score={spectrumScore} />}
              trackingData={trackingData}
            />
          </Box>
        );
      })}
    </React.Fragment>
  );
}

export default MotivatingValuesTraits;
