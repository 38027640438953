import React, { useState } from "react";
import get from "lodash/fp/get";
import styled from "styled-components";
import { track } from "../../lib";
import { withTooltip } from "../hoc/withTooltip";
import { lightenDarkenColor } from "../utils";
import { EVENT } from "../utils/constants";
import {
  WHITE,
  BLACK,
  DARK_GREY
} from "../utils/theme";
import Button from "./Button";
import { Flex, Box } from "./FlexBox";
import { Icon } from ".";

const BorderedBox = styled(Box)`
  width: 100%;
  padding: 8px;
  border: 1px solid ${({ color }) => color};
  border-top: 0;
  border-radius: 0 0 10px 10px;
`;

const TabButton = styled(Button)`
  color: ${BLACK};
  background-color: ${({ color }) => lightenDarkenColor(color, 0.7)};
  flex: 1;
  border-radius: 10px 10px 0 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;

  &:hover, &:active {
    color: ${WHITE};
    background-color: ${({ color }) => color};
  }
`;

const SelectedTabButton = styled(TabButton)`
  color: ${WHITE};
  background-color: ${({ color }) => color};
`;

const TraitContent = styled.div`
  ul {
    list-style-type: none;
    padding-left: 0;
  }
`;

const LockedTabButton = styled(TabButton)`
  color: ${DARK_GREY};
  background-color: ${WHITE};

  &:hover, &:active {
    color: ${BLACK};
    background-color: ${WHITE};
    cursor: not-allowed;
  }
`;

const FlexWithTooltip = withTooltip(Flex, {
  arrow: true,
  theme: "cloverleaf",
  style: { width: "100%" }, // Fix for the tooltip wrapper forcing display: inline
});

function DetailTabs({ details, color = BLACK, trackingData }) {
  const [currentItem, setCurrentItem] = useState(0);

  if (currentItem >= details.length) {
    return null;
  }

  const buildTabs = () => details.map((detail, index) => {
    const TabComponent = currentItem === index ? SelectedTabButton : TabButton;
    const Tab = detail.isLocked ? LockedTabButton : TabComponent;
    const tooltip = detail.isLocked
      ? { html: <Box w={200}>{detail.lockedLabel}</Box>, position: "bottom" }
      : undefined;

    const { name: eventName, props: properties } = EVENT.ContentViewed;

    const handleClick = () => {
      setCurrentItem(index);
      if (trackingData) {
        track(eventName, {
          ...trackingData,
          subElement: detail.button,
          action: properties.action.open,
          contentType: properties.contentType.trait,
        });
      }
    };

    return (
      <FlexWithTooltip key={detail.button} w={1} tooltip={tooltip}>
        <Tab
          onClick={!detail.isLocked && handleClick}
          small
          color={color}
        >
          {detail.button}
          {detail.isLocked && <Icon size="1x" icon="lock" style={{ marginLeft: "4px" }} />}
        </Tab>
      </FlexWithTooltip>
    );
  });

  let traitContent = null;
  const detailContent = get([currentItem, "content"], details);
  // If this is an object, render it as a child component, otherwise render it as HTML
  if (typeof detailContent === "object") {
    traitContent = <TraitContent key={currentItem}>{detailContent}</TraitContent>;
  } else {
    {/* eslint-disable-next-line react/no-danger */}
    traitContent = <TraitContent dangerouslySetInnerHTML={{ __html: detailContent }} />;
  }

  return (
    <Flex w={1} mt={8} column>
      <Flex>
        {buildTabs()}
      </Flex>
      <BorderedBox color={color} p={1}>
        {traitContent}
      </BorderedBox>
    </Flex>
  );
}

export default DetailTabs;
