import React from "react";
import PropTypes from "prop-types";
import Image from "next/legacy/image";
import { Box, Grommet } from "grommet";
import { Modal } from "@/cloverleaf-ui/components";
import { themeStyle } from "@/cloverleaf-ui/theme";

function StrengthscopeGraphic({ graphicUrl }) {
  const [isShowingGraphicModal, setIsShowingGraphicModal] = React.useState(false);

  return (
    <Grommet theme={themeStyle} style={{ position: "relative" }}>
      {isShowingGraphicModal && (
        <Modal onClose={() => setIsShowingGraphicModal(false)}>
          <Box
            flex={false}
            pad={{ horizontal: "medium", bottom: "medium" }}
            width="xxlarge"
          >
            <Image
              alt="Strengthscope Graphic"
              src={graphicUrl}
              width="100%"
              height="85%"
              layout="responsive"
            />
          </Box>
        </Modal>
      )}
      <Box fill flex={false} onClick={() => setIsShowingGraphicModal(true)} style={{ cursor: "zoom-in" }}>
        <Image width="364px" height="291px" src={graphicUrl} alt="Strengthscope Graphic" />
      </Box>
    </Grommet>
  );
}

StrengthscopeGraphic.displayName = "StrengthscopeGraphic";

StrengthscopeGraphic.propTypes = {
  graphicUrl: PropTypes.string.isRequired,
};

export default StrengthscopeGraphic;
