import React from "react";
import { node } from "prop-types";
import { Flex } from "../../ui";
import { WHITE, BLACK } from "../../utils/theme";

class ChartTooltipContainer extends React.PureComponent {
  static propTypes = {
    children: node.isRequired,
  }

  render() {
    const { children } = this.props;

    return (
      <Flex column className="tippy-tooltip" background={WHITE} style={{ color: BLACK }}>
        {children}
      </Flex>
    );
  }
}

export default ChartTooltipContainer;
