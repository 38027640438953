import React from "react";
import {
  string,
  arrayOf,
  shape
} from "prop-types";
import castArray from "lodash/fp/castArray";
import isNumber from "lodash/fp/isNumber";
import styled from "styled-components";
import { trackingDataProp } from "../../../lib/graphql/propTypes";
import { isLastIndex } from "../../utils";
import { EVENT } from "../../utils/constants";
import { WHITE } from "../../utils/theme";
import { Flex } from "../FlexBox";
import TextLabel from "../styled/TextLabel";
import {
  arrowPositionByScore,
  getArrowColor,
  getFillColor,
  getFillDirection,
  getTransformOrigin,
  instinctiveDrivesProptype,
  scaleByScore,
  SpectrumDirection,
  SpectrumLayout,
  SPECTRUM_HEIGHT,
  SPECTRUM_WIDTH
} from "./instinctiveDrivesGraph.utils";
import InstinctiveDrivesSpectrumLabel from "./InstinctiveDrivesSpectrumLabel";
import InstinctiveDrivesTeamSpectrum from "./InstinctiveDrivesTeamSpectrum";

const InstinctiveDriveBox = styled(Flex)`
  height: ${SPECTRUM_HEIGHT}px;
  width: ${SPECTRUM_WIDTH}px;
  border: ${props => `2px solid ${props.color}`};
  border-radius: 10px;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

// This level is so that we can contain all graph data
// But excluding the plotted users since we cant have
// overflow hidden on InstinctiveDriveLabelBackground
// Because we dont want to crop the tooltip
const InstinctiveDriveLabelBackgroundWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

const InstinctiveDriveLabelBackground = styled(Flex)`
  position: relative;
  width: 100%;
  height: 18px;
  background: ${props => props.color};
  justify-content: center;
  align-items: center;
`;

const InstinctiveDriveArrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  top: 2px;
  left: 0px;
  display: ${props => (props.score === 5 ? "none" : "")};
  border-left: ${SPECTRUM_WIDTH * 0.5}px solid transparent;
  border-right: ${SPECTRUM_WIDTH * 0.5}px solid transparent;
  border-bottom: ${props => (props.score < 5 ? "" : `${SPECTRUM_WIDTH * 0.2}px solid ${getArrowColor(props.color)}`)};
  border-top: ${props => (props.score > 5 ? "" : `${SPECTRUM_WIDTH * 0.2}px solid ${getArrowColor(props.color)}`)};
  transform: ${props => `translateY(${arrowPositionByScore[props.score]}px)`};
`;

const InstinctiveDriveFill = styled.div`
  position: absolute;
  top: 3px;
  left: 0px;
  width: ${SPECTRUM_WIDTH}px;
  height: 12px;
  background-color: ${props => getFillColor(props.color)};
  transform: ${props => `translateY(${getFillDirection(props.direction) * 15}px) scaleY(${scaleByScore[props.score]})`};
  transform-origin: ${props => getTransformOrigin(props.direction)};
`;

const InstinctiveDrivesBoxWrapper = styled(Flex)`
  margin-right: ${props => (props.isLastIndex ? "0px" : "10px")};

  @media (max-width: 440px) {
    margin-right: 4px;
  }
`;

function InstinctiveDrivesGraph({
  instinctiveDrives = [],
  layout = SpectrumLayout.ME,
  users, primaryUser, trackingData,
}) {
  return (
    <Flex maxWidth={320}>
      <InstinctiveDrivesSpectrumLabel layout={layout} />
      <Flex pl="5px">
        {castArray(instinctiveDrives).map(({ color, label, score }, index) => (
          <InstinctiveDrivesBoxWrapper key={label} isLastIndex={isLastIndex(index, instinctiveDrives)} align="center" column>
            <InstinctiveDriveBox color={color} layout={layout}>
              <InstinctiveDriveLabelBackgroundWrapper>
                <InstinctiveDriveLabelBackground color={color}>
                  {(score >= 5 || score === SpectrumDirection.USE) && (
                    <InstinctiveDriveFill
                      color={color}
                      score={score}
                      direction={SpectrumDirection.USE}
                    />
                  )}
                  {isNumber(score) && <InstinctiveDriveArrow score={score} color={color} />}
                  {layout === SpectrumLayout.ME && (
                    <TextLabel color={WHITE} uppercase small center style={{ userSelect: "none" }}>
                      {label}
                    </TextLabel>
                  )}
                  {(score <= 5 || score === SpectrumDirection.AVOID) && (
                    <InstinctiveDriveFill
                      color={color}
                      score={score}
                      direction={SpectrumDirection.AVOID}
                    />
                  )}
                </InstinctiveDriveLabelBackground>
              </InstinctiveDriveLabelBackgroundWrapper>
              {layout === SpectrumLayout.TEAM && (
                <InstinctiveDrivesTeamSpectrum
                  users={users}
                  traitName={label}
                  primaryUser={primaryUser}
                  trackingData={{
                    ...trackingData,
                    element: EVENT.ContentViewed.props.element.teamInstinctiveDrivesChart,
                  }}
                />
              )}
            </InstinctiveDriveBox>
            {layout === SpectrumLayout.TEAM && (
              <TextLabel color={color} uppercase small center style={{ userSelect: "none" }}>
                {label}
              </TextLabel>
            )}
          </InstinctiveDrivesBoxWrapper>
        ))}
      </Flex>
    </Flex>
  );
}

InstinctiveDrivesGraph.displayName = "InstinctiveDrivesGraph";

InstinctiveDrivesGraph.propTypes = {
  instinctiveDrives: instinctiveDrivesProptype,
  // Which layout style should be displayed? ME or TEAM?
  layout: string,
  users: arrayOf(
    shape({
      id: string.isRequired,
      fullName: string.isRequired,
      avatar: string,
      scores: shape({
        instinctiveDrives: shape({
          traits: arrayOf(shape({
            name: string.isRequired,
            value: string.isRequired,
          })),
        }).isRequired,
      }).isRequired,
    }),
  ),
  primaryUser: shape({ id: string.isRequired, fullName: string.isRequired, avatar: string }),
  trackingData: trackingDataProp,
};

export default InstinctiveDrivesGraph;
