import React from "react";
import {
  bool,
  arrayOf,
  shape,
  string
} from "prop-types";
import {
  Box,
  Flex,
  TextLabel,
  SingleLineTextBox
} from "../../../ui";
import Avatar from "../../../ui/Avatar";
import ChartTooltipContainer from "../../Charts/ChartTooltipContainer";

function RenderTooltip(props) {
  if (!props.active) return null;

  const { users } = props.payload[0].payload;

  return (
    <ChartTooltipContainer>
      {users.map(user => (
        <Flex mb="4px" align="center" key={user.id} w={1}>
          <Box mr={1}>
            <Avatar size={2} expandable={false} user={user} />
          </Box>
          <SingleLineTextBox>
            <TextLabel>{user.fullName}</TextLabel>
          </SingleLineTextBox>
        </Flex>
      ))}
    </ChartTooltipContainer>
  );
}

RenderTooltip.propTypes = {
  active: bool.isRequired,
  payload: arrayOf(
    shape({
      payload: shape({
        users: arrayOf(
          shape({
            id: string.isRequired,
            fullName: string.isRequired,
            avatar: string,
          }),
        ),
      }),
    }),
  ).isRequired,
};

export default RenderTooltip;
