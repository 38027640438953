import React from "react";
import { string } from "prop-types";
import { Flex } from "../FlexBox";
import TextLabel from "../styled/TextLabel";
import {
  getSpectrumAxisLabels,
  SpectrumLayout,
  SPECTRUM_HEIGHT,
} from "./instinctiveDrivesGraph.utils";
import { useLocale } from "@/lib/hooks";

function InstinctiveDrivesSpectrumLabel({ layout = SpectrumLayout.ME }) {
  const { t } = useLocale();

  return (
    <Flex h={SPECTRUM_HEIGHT}>
      <Flex column justify="space-between">
        <TextLabel small uppercase style={{ userSelect: "none" }}>
          {t("id.spectrum-direction.use")}
        </TextLabel>
        <TextLabel small uppercase style={{ userSelect: "none" }}>
          {t("id.spectrum-direction.avoid")}
        </TextLabel>
      </Flex>
      <Flex pl="4px" py="8px" column justify="space-between">
        {getSpectrumAxisLabels(layout).map((axisNumber, index) => (
        // eslint-disable-next-line react/no-array-index-key
          <TextLabel key={`${axisNumber}_${index}`} small uppercase style={{ lineHeight: "normal", userSelect: "none" }}>
            {axisNumber}
          </TextLabel>
        ))}
      </Flex>
    </Flex>
  );
}

InstinctiveDrivesSpectrumLabel.propTypes = {
  layout: string,
};

export default InstinctiveDrivesSpectrumLabel;
