import every from "lodash/fp/every";
import getOr from "lodash/fp/getOr";
import map from "lodash/fp/map";
import size from "lodash/fp/size";
import toUpper from "lodash/fp/toUpper";
import { convertHexToRGBA, toInt } from "../../utils";
import { ASSESSMENT } from "../../utils/theme";

// Height in pixels of each "instinctive drive" box
export const SPECTRUM_HEIGHT = 230;

// Width in pixels of the spectrum components.
export const SPECTRUM_WIDTH = 70;

export const SpectrumDirection = {
  USE: "USE",
  AVOID: "AVOID",
  DIVERSE: "DIVERSE",
  NONE: "NONE",
};

export const SpectrumLayout = {
  ME: "ME",
  TEAM: "TEAM",
};

export const getSpectrumAxisLabels = layout => (layout === SpectrumLayout.ME
  ? [9, 8, 7, 6, 5, 5, 4, 3, 2, 1]
  : [9, 8, 7, 6, 5, 4, 3, 2, 1]);

export const scaleByScore = {
  [SpectrumDirection.AVOID]: 10,
  1: 7.5,
  2: 6.0,
  3: 4.0,
  4: 2.0,
  5: 1.0,
  6: 2.0,
  7: 4.0,
  8: 6.0,
  9: 7.5,
  [SpectrumDirection.USE]: 10,
};

export const arrowPositionByScore = {
  1: 106,
  2: 88,
  3: 64,
  4: 40,
  5: 0.0, // Arrow should be ignored
  6: -40,
  7: -64,
  8: -88,
  9: -106,
};

export const colorByTraitName = {
  VERIFY: ASSESSMENT.INSTINCTIVEDRIVES.VERIFY,
  AUTHENTICATE: ASSESSMENT.INSTINCTIVEDRIVES.AUTHENTICATE,
  COMPLETE: ASSESSMENT.INSTINCTIVEDRIVES.COMPLETE,
  IMPROVISE: ASSESSMENT.INSTINCTIVEDRIVES.IMPROVISE,
};

export const buildInstinctiveDrivesFromUserTraits = map((trait = {}) => ({
  label: trait.name,
  color: colorByTraitName[toUpper(trait.nameKey)],
  score: toInt(trait.value),
}));

export const getArrowColor = color => convertHexToRGBA(color, 0.7);

export const getFillColor = color => convertHexToRGBA(color, 0.5);

export const getFillDirection = direction => (SpectrumDirection.USE === direction ? -1 : 1);

export const getTransformOrigin = direction => (SpectrumDirection.USE === direction ? "bottom" : "top");

const possibleScores = [
  1, 2, 3, 4, 5, 6, 7, 8, 9,
  SpectrumDirection.USE, SpectrumDirection.AVOID, SpectrumDirection.DIVERSE, SpectrumDirection.NONE,
];

export const instinctiveDrivesProptype = (props, propName) => {
  const layout = getOr(SpectrumLayout.ME, "layout", props);
  const instinctiveDrives = props[propName] || [];

  // 1. There must be 0 or 4 instinctive drives
  if (size(instinctiveDrives) !== 0 && size(instinctiveDrives) !== 4) {
    return new Error("Must be exactly 4 Instinctive Drives.");
  }

  // 2. Each instinctive drive must be an object with label, value, score
  if (!every(({ label, color, score } = {}) => !!label && !!color && !!score, instinctiveDrives)) {
    return new Error("Must contain a label, color, and score for each Instinctive Drive.");
  }

  // 3. Scores must be one of the available options
  if (!every(({ score } = {}) => possibleScores.includes(score), instinctiveDrives)) {
    return new Error("Entered an invalid score. Possible scores are: \"1, 2, 3, 4, 5, 6, 7, 8, 9, 'USE', 'AVOID', 'DIVERSE' or 'NONE'\"");
  }

  // 4. ME layout uses numeric scores and team layout uses spectrum fill directions
  const expectedType = layout === SpectrumLayout.ME ? "number" : "string";

  // eslint-disable-next-line valid-typeof
  if (!every(({ score } = {}) => typeof score === expectedType, instinctiveDrives)) {
    return new Error("Instinctive Drives scores must be numeric for a ME layout or a fill-direction for TEAM layout.");
  }

  return null;
};
