import React from "react";
import { Box } from "../../ui";
import { EVENT } from "../../utils/constants";
import { useContentManager } from "../ContentManager";
import ToggleButton from "../ToggleButton";
import { getInstinctiveDrivesColorByName } from "./InstinctiveDrives.utils";

function InstinctiveDrivesTraits({ teamId, traits, trackingData }) {
  const { wrapContent } = useContentManager();
  const { props: properties } = EVENT.ContentViewed;

  return (
    <>
      {traits.map((trait) => {
        const { name, nameKey, value } = trait;

        return (
          <Box w={1} mt={1} key={name}>
            <ToggleButton
              title={name}
              subtitle={value}
              details={wrapContent(trait, {
                border: true,
              })}
              color={getInstinctiveDrivesColorByName(nameKey)}
              small
              trackingData={trackingData ? {
                ...trackingData,
                action: properties.action.open,
                contentType: properties.contentType.trait,
                element: name,
                teamId,
              } : undefined}
            />
          </Box>
        );
      })}
    </>
  );
}

export default InstinctiveDrivesTraits;
