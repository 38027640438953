import React from "react";
import {
  arrayOf,
  string,
  number,
} from "prop-types";
import withTranslation from "next-translate/withTranslation";
import styled from "styled-components";
import {
  Flex,
  TextLabel,
  Box,
} from "../../ui";
import { WHITE, GREEN } from "../../utils/theme";
import { spectrumMargin, spectrumBorderRadius } from "./utils";

const SpectrumContainer = styled.div`
  background-color: ${WHITE};
  border-radius: 5px;
  border: 1px solid ${GREEN};
  height: 8px;
  width: 100%;
`;

const SpectrumValue = styled.div`
  margin: ${props => (spectrumMargin(props.score, props.range))};
  background-color: ${GREEN};
  border-radius: ${props => spectrumBorderRadius(props.score, props.range)};
  height: 8px;
`;

const SpectrumLabel = styled(TextLabel)`
  margin-top: 6px;
  font-size: 11px;
  text-transform: uppercase;
`;

class LabeledSpectrum extends React.PureComponent {
  static propTypes = {
    title: string,
    labels: arrayOf(string).isRequired,
    score: number,
    range: arrayOf(number),
  }

  static defaultProps = {
    score: undefined,
    title: undefined,
    range: undefined,
  }

  render() {
    const { title, labels, score, range, i18n } = this.props;
    const { t } = i18n;

    return (
      <Box w={1} mt={1}>
        {title && <SpectrumLabel>{t(`${title.toLowerCase()}`)}</SpectrumLabel>}
        <SpectrumContainer>
          <SpectrumValue score={score} range={range} />
        </SpectrumContainer>
        <Flex w={1} justify="space-between">
          {labels.map(label => <SpectrumLabel key={label}>{t(`${label}`)}</SpectrumLabel>)}
        </Flex>
      </Box>
    );
  }
}

export default withTranslation(LabeledSpectrum, "common");
