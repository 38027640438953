import React from "react";
import {
  shape,
  string,
  number
} from "prop-types";
import LabeledSpectrum from "../LabeledSpectrum";
import { personalitySpectrumValues, personalitySpectrumScore } from "../utils";
import { useLocale } from "@/lib/hooks";

function PersonalitySpectrum({ trait, index }) {
  const { t } = useLocale();

  if (personalitySpectrumValues[index]) {
    return (
      <LabeledSpectrum
        labels={Object.keys(personalitySpectrumValues[index]).map(key => t(`16types.spectrum-labels.${key.toLowerCase()}`))}
        score={personalitySpectrumScore(trait, index)}
      />
    );
  }

  return null;
}

PersonalitySpectrum.propTypes = {
  trait: shape({
    name: string.isRequired,
    secondaryName: string.isRequired,
    description: string.isRequired,
    value: number,
  }).isRequired,
  index: number.isRequired,
};

export default PersonalitySpectrum;
