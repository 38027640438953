import React from "react";
import {
  array,
  string,
  node,
  bool,
  oneOfType,
  shape,
  number,
  arrayOf
} from "prop-types";
import { Flex, Box } from "../../ui";
import TitleToggleContainer from "../TitleToggle";
import ToggleButton from "../ToggleButton";

const titleProp = oneOfType([string.isRequired, node.isRequired]);

class AssessmentNugget extends React.PureComponent {
  static propTypes = {
    traits: arrayOf(shape({
      title: titleProp.isRequired,
      details: oneOfType([string.isRequired, node.isRequired, shape({
        showAvatars: bool,
        tabs: node,
        users: array,
      })]),
      subtitle: oneOfType([string.isRequired, number.isRequired]),
    })).isRequired,
    title: titleProp,
    description: oneOfType([string, node]),
    asHTML: bool, // Is the description an html string?
    startExpanded: bool,
    column: bool,
    trackingData: shape({
      page: string,
      target: string,
      component: string,
      teamId: string,
    }),
  }

  static defaultProps = {
    asHTML: false,
    column: false,
    startExpanded: false,
    title: undefined,
    description: undefined,
    trackingData: undefined,
  };

  render() {
    const {
      traits, title, startExpanded, description, asHTML, column, trackingData,
    } = this.props;

    return (
      <Flex align="flex-start" wrap px={2}>
        {title && description && (
          <Box w={1} mb={1}>
            <TitleToggleContainer
              title={title}
              description={description}
              asHTML={asHTML}
              startExpanded={startExpanded}
              trackingData={trackingData}
            />
          </Box>
        )}
        <Flex wrap column={column} w={1}>
          {traits.map(({ key, ...traitProps }) => (
            <Box mb={1} mr={1} key={key}>
              <ToggleButton {...traitProps} trackingData={{ ...trackingData, element: traitProps.title }} />
            </Box>
          ))
          }
        </Flex>
      </Flex>
    );
  }
}

export default AssessmentNugget;
