import React from "react";
import {
  arrayOf,
  shape,
  string
} from "prop-types";
import styled from "styled-components";
import { trackingDataProp } from "../../../lib/graphql/propTypes";
import { staggerPrimaryUserForVecticalChart } from "../../composite/Charts/utils";
import { formatInstinctiveDrivesCoordinatesAndUsers } from "../../composite/TeamDashboard/Boxes/CultureBox/InstinctiveDrivesTeamView/InstinctiveDrivesTeamView.utils";
import BlankTeamSpectrum from "../../composite/TeamDashboard/TeamSpectrum/BlankTeamSpectrum";
import { ASSESSMENT } from "../../utils/constants";
import { Flex } from "../FlexBox";

const Wrapper = styled(Flex)`
  position: absolute;
  top: 0;
  bottom: 11px;
  width: 100%;
  padding-top: 11px;
  overflow: visible;
`;

function InstinctiveDrivesTeamSpectrum({ users, traitName, primaryUser, trackingData }) {
  const spectrumUsers = formatInstinctiveDrivesCoordinatesAndUsers(users);
  const spectrumUsersWithPrimaryUserStaggered = staggerPrimaryUserForVecticalChart(spectrumUsers, primaryUser);

  return (
    <Wrapper>
      <BlankTeamSpectrum
        assessment={ASSESSMENT.INSTINCTIVEDRIVES}
        coordinatesWithUsers={spectrumUsersWithPrimaryUserStaggered[traitName]}
        range={{ min: { x: 1, y: 1 }, max: { x: 5, y: 9 } }}
        primaryUser={primaryUser}
        trackingData={trackingData}
      />
    </Wrapper>
  );
}

InstinctiveDrivesTeamSpectrum.propTypes = {
  users: arrayOf(
    shape({
      id: string.isRequired,
      fullName: string.isRequired,
      avatar: string,
      scores: shape({
        instinctiveDrives: shape({
          traits: arrayOf(shape({
            name: string.isRequired,
            value: string.isRequired,
          })),
        }).isRequired,
      }).isRequired,
    }),
  ).isRequired,
  traitName: string.isRequired,
  primaryUser: shape({ id: string.isRequired, fullName: string.isRequired, avatar: string }),
  trackingData: trackingDataProp,
};

export default InstinctiveDrivesTeamSpectrum;
