import React from "react";
import {
  arrayOf,
  number,
  shape,
  string
} from "prop-types";
import {
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { trackingDataProp } from "../../../../lib/graphql/propTypes";
import { Flex } from "../../../ui";
import { below, DROPDOWNS } from "../../../utils/zIndex";
import RenderTooltip from "./SpectrumTooltip";
import TeamSpectrumScatterShape from "./TeamSpectrumScatterShape";
import { formatData } from "./utils";

function BlankTeamSpectrum(props) {
  const { assessment, range, trackingData } = props;
  const { min, max } = range;
  const data = formatData(props);

  return (
    <Flex w={1}>
      <ResponsiveContainer>
        <ScatterChart style={{ pointerEvents: "none" }}>
          <XAxis
            dataKey="x"
            type="number"
            hide
            domain={[min.x, max.x]}
            tickCount={0}
            interval={0}
          />
          <YAxis
            dataKey="y"
            type="number"
            hide
            tickSize={5}
            interval={0}
            domain={[min.y, max.y]}

          />
          <Tooltip
            content={RenderTooltip}
            cursor={false}
            isAnimationActive={false}
            wrapperStyle={{ zIndex: below(DROPDOWNS) }}
          />
          <Scatter
            id={`${assessment}-scatter-scores`}
            data={data}
            shape={({ key, ...shapeProps }) => (
              <TeamSpectrumScatterShape
                key={key}
                {...shapeProps}
                primaryUser={props.primaryUser}
                assessment={assessment}
                trackingData={trackingData}
              />
            )}
            isAnimationActive={false}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </Flex>
  );
}

BlankTeamSpectrum.propTypes = {
  assessment: string.isRequired,
  coordinatesWithUsers: arrayOf(shape({
    x: number,
    y: number,
    users: arrayOf(shape({ id: string.isRequired, avatar: string, fullName: string.isRequired })),
  })),
  range: shape({
    min: shape({ x: number, y: number }),
    max: shape({ x: number, y: number }),
  }).isRequired,
  primaryUser: shape({ id: string.isRequired }),
  trackingData: trackingDataProp,
};

export default BlankTeamSpectrum;
