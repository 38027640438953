import flatten from "lodash/fp/flatten";
import get from "lodash/fp/get";
import isUndefined from "lodash/fp/isUndefined";
import { floatTo2Decimals } from "../../../utils";

export const getUserCultureTraits = get("scores.culture.traits");

export const formatData = ({
  coordinatesWithUsers = [],
  max,
  min,
  average,
  rangeMax,
  rangeMin,
  scoreForOffset, // the user's score that will be used to draw the line from the average.
}) => {
  const rangeElements = [];
  const averageElements = [];

  if (!isUndefined(rangeMax) && !isUndefined(rangeMin)) {
    const rangeW = floatTo2Decimals((rangeMax - rangeMin) / (max - min));
    rangeElements.push({ x: rangeMin, y: 0, w: rangeW, type: "range" });
  }

  if (!isUndefined(average)) {
    const offsetW = floatTo2Decimals(Math.abs((scoreForOffset - average) / max));
    averageElements.push({ x: scoreForOffset > average ? average : scoreForOffset, y: 0, w: offsetW, type: "offset" });
    averageElements.push({ x: average, type: "average" });
  }

  return flatten([rangeElements, averageElements, coordinatesWithUsers]);
};
