import React from "react";
import { string, number } from "prop-types";
import { trackingDataProp } from "../../../lib/graphql/propTypes";
import { Box } from "../../ui";
import { strengthCategoryMapping } from "../TeamDashboard/utils";
import ToggleButton from "../ToggleButton";

function StrengthsToggle({
  name, secondaryName, description, rank, trackingData, ...toggleProps
}) {
  return (
    <Box w={1} key={name} mt={1}>
      <ToggleButton
        title={name}
        icon={strengthCategoryMapping[secondaryName].icon}
        subtitle={rank}
        details={description}
        color={strengthCategoryMapping[secondaryName].color}
        trackingData={trackingData}
        testId={`strength-toggle-${name}`}
        {...toggleProps}
      />
    </Box>
  );
}

StrengthsToggle.propTypes = {
  name: string.isRequired,
  secondaryName: string.isRequired,
  description: string.isRequired,
  rank: number.isRequired,
  trackingData: trackingDataProp,
};

export default StrengthsToggle;
